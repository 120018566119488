<template>
  <header class="banner">


    <div class="navbar navbar-expand-xl">

      <!-- <a aria-label="HLA Trad SECURITIES" class="navbar-brand" href="/">
      </a> -->

      <div class="download-box-abc">
        <a href="/">
          <img src="@/assets/images/logo_a.png" />
          <img src="@/assets/images/logo.png" />
          <text>
            MERRILL
          </text>
        </a>
      </div>




      <button class="navbar-toggler" type="button" aria-expanded="false" aria-label="Toggle navigation">

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.14 39.14">
          <g id="Layer_3" data-name="Layer 3">
            <g id="Layer_3-3" data-name="Layer 3">
              <rect id="bar1" x="9.96" y="18" width="30" height="1.26"></rect>
              <rect id="bar2" x="9.96" y="24" width="30" height="1.26"></rect>
            </g>
          </g>
        </svg>

      </button>


      <div class="navbar--container">

        <ul id="menu-secondary-menu" class="nav">
          <li id="menu-item-31" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-31">
            <a
              title="Contact" href="javascript:void(0)" @click="openCustomer" style="cursor:pointer;">
              <img src="@/assets/images/customer.png" style="height: 35px;" />
            </a>
          </li>
        </ul>

        <ul id="menu-primary-menu" class="nav">
          <li id="menu-item-26" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-26"><a
              title="About" href="/about/"><span>Sobre</span></a></li>
          <li id="menu-item-14886" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-14886"><a
              title="Strategies" href="/strategies/"><span>Estratégias</span></a></li>
          <!-- <li id="menu-item-30" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-30"><a
              title="Team" href="/team/"><span>Team</span></a></li> -->
          <li id="menu-item-24" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-24"><a
              title="Portfolio" href="/portfolio/"><span>Portfólio</span></a></li>
          <li id="menu-item-29" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-29"><a
              title="ESG" href="/esg/"><span>ESG</span></a></li>
          <!-- <li id="menu-item-28" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-28"><a
              title="News" href="/news/"><span>News</span></a></li> -->
          <li id="menu-item-10672"
            class="nav-search menu-item menu-item-type-custom menu-item-object-custom menu-item-10672">
            <form role="search" method="get" class="wp-block-search" action="">
              <input type="search" class="wp-block-search__input" placeholder="Search …" value="" name="s">
              <button type="submit" class="wp-block-search__button">Pesquisa</button>
            </form><a title="Search" href="#"><span>Pesquisa</span></a>
          </li>
        </ul>

      </div>

    </div>

  </header>
</template>

<script setup>
  // import { ref, onMounted, onUnmounted } from "vue";
  // onMounted(() => {
  //   require('@/assets/js/index.min.js')
  // });
  const openCustomer = () => {
    try {
      _MICHAT.startChat()
    } catch {}
  }
</script>

<style lang="scss" scoped>
  .cls-1 {
    fill: #fff;
  }

  .download-box-abc{
    height: 100px;
    line-height: 100px;
    margin: 20px 0;
    color:#fff;
    text-align: center;
    margin-top: 30px;

    a{
      display: flex;
      align-items: center;
      text-decoration:none;
    }

    text{
      margin-left:10px;
      font-size: 32px;
    }

    img{
      height: 50px;
      @media screen and (max-width: 640px){
        width: 160px;
      }
      &:first-of-type{
        margin-right: 5px;
      }
    }
  }
</style>